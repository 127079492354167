import React from 'react'

const ToolCard = React.forwardRef(({toolInfo}, ref) => {
  return (
    <div
      className="tool-card"
      style={{backgroundColor: toolInfo.backgroundColor}}
      ref={ref}
    >
      <span style={{color: toolInfo.textColor}}>
        {toolInfo.text}
      </span>
    </div>
  )
})

export default ToolCard
