import React, {useEffect, useMemo, useRef, useState} from "react";
import Image from 'next/image'
import sio2Subst from '../../public/images/main/sio2_substr.svg'
import leftBlur from '../../public/images/main/blur_left.svg'
import rightBlur from '../../public/images/main/blur_right.svg'
import Container from "../../components/Container";
import {FormattedMessage} from "react-intl";
import BaseHeadline from "../../components/BaseHeadline";
import BaseTitle from "../../components/BaseTitle";
import BaseCaption from "../../components/BaseCaption";
import icons from "../../public/icons/icons.svg";
import {Link} from "react-scroll";
import {CASES_LIST} from "./const";
import CaseCard from "./components/CaseCard";
import Plx from "react-plx";
import useWindowSize from "../../hooks/windowSizeHook";
import {localeSelectors} from "../../models/locale";
import AnimatedElements from "./components/AnimatedElements";
const ScrollScreens = () => {
  const windowSize = useWindowSize()

  const [casesListHeight, setCasesListHeight] = useState(0)
  const [listRef, setListRef] = useState(null)
  const [mainRef, setMainRef] = useState(null)
  const substrRef = useRef(null)

  const locale = localeSelectors.useValue()

  const mainScreenHeight = useMemo(() => {
    return (mainRef?.offsetHeight ?? 0)
  }, [mainRef, windowSize])

  const duration = useMemo(() => {
    return mainScreenHeight + casesListHeight
  }, [mainScreenHeight, casesListHeight])

  useEffect(() => {
    if (!listRef) return

    setCasesListHeight(listRef.offsetHeight * 0.8)
  }, [listRef, windowSize])

  return (
    <Plx
      id={`scroll-container`}
      className={`scroll-container ${locale === 'ru' ? 'scroll-container_ru' : ''}`}
      parallaxData={[
        {
          start: 0,
          end: mainScreenHeight,
          properties: [
            {
              startValue: '#303030',
              endValue: '#212121',
              property: "backgroundColor",
            },
          ],
        },
        {
          start: mainScreenHeight,
          end: duration,
          properties: [
            {
              startValue: '#212121',
              endValue: '#303030',
              property: "backgroundColor",
            },
          ]
        }
      ]}
    >
      <div
        className={'main'}
        ref={node => setMainRef(node)}
      >
        <div className="main__body">
          <div
            className="main__img"
            ref={substrRef}
          >
            <div className="substr-light-1">
              <Image
                src={leftBlur}
                alt={'leftLight'}
              />
            </div>

            <Image
              src={sio2Subst}
              alt='sio2-substr'
            />

            <div className="substr-light-2">
              <Image
                src={rightBlur}
                alt={'rightBlur'}
              />
            </div>
          </div>

          <div className="main__text-block">
            <BaseHeadline>
              <FormattedMessage id={'main_section_headline'}/>
            </BaseHeadline>

            <BaseTitle
              Tag={'h1'}
            >
              <FormattedMessage id={'main_section_title_1'}/>
              <br/>
              <FormattedMessage id={'main_section_title_2'}/>
            </BaseTitle>

            <BaseCaption>
              <FormattedMessage id={'main_section_caption'}/>
            </BaseCaption>
          </div>

          <Link
            className={'base-icon-button main__btn'}
            to={'cases'}
            smooth
            duration={1000}
          >
            <svg>
              <use href={`${icons.src}#arrow_down`}/>
            </svg>
          </Link>
        </div>
      </div>

      <AnimatedElements
        substrRef={substrRef}
        duration={duration}
        mainScreenHeight={mainScreenHeight}
      />

      <Container id={'cases'} customClass="cases">
        <div className="cases__block">
          <BaseHeadline>
            <FormattedMessage id={'cases'}/>
          </BaseHeadline>

          <BaseTitle>
            <FormattedMessage id={'case_section_title_1'}/>
            <br/>
            <FormattedMessage id={'case_section_title_2'}/>
          </BaseTitle>

          <div className="cases__list" ref={(node) => setListRef(node)}>
            {CASES_LIST.map(caseInfo => {
              return <CaseCard
                key={caseInfo.textId}
                id={caseInfo.id}
                caseInfo={caseInfo}
              />
            })}
          </div>
        </div>
      </Container>
    </Plx>
  )
}

export default ScrollScreens
