import React from 'react'
import Image from "next/image";
import BaseHeadline from "../../../components/BaseHeadline";
import FormattedMsgWithLineBreak from "../../../components/FormattedMsgWithLineBreak";
import BaseCaption from "../../../components/BaseCaption";

const MemberCard = ({member}) => {
  return (
    <div className={'member-card'}>
      <div className="member-card__header">
        <div className="member-card__img">
          <Image
            src={member.img}
            alt={'member'}
          />
        </div>

        <div className="member-card__light"/>
      </div>

      <BaseHeadline>
        <FormattedMsgWithLineBreak id={member.nameId}/>
      </BaseHeadline>

      <BaseCaption isSmall>
        <FormattedMsgWithLineBreak id={member.infoId}/>
      </BaseCaption>
    </div>
  )
}

export default MemberCard
