import watt from '../../public/images/releases/watt.png'
import whalemap from '../../public/images/releases/whalemap.png'
import laguna from '../../public/images/releases/laguna.png'
import weighing from '../../public/images/releases/weighing.png'
import vcWhite from '../../public/images/releases/vc_white.svg'
import wattBig from '../../public/images/releases/watt_full.png'

export const RELEASES_LIST = [
  {
    id: 'watt',
    descriptionId: 'watt_description',
    textId: 'watt_text',
    img: watt,
    bigImg: wattBig,
    platformLogo: vcWhite,
    available: true
  },
  {
    id: 'whalemap',
    descriptionId: 'whalemap_description',
    textId: '',
    img: whalemap,
    bigImg: null,
    platformLogo: null,
    available: false
  },
  {
    id: 'laguna',
    descriptionId: 'laguna_description',
    textId: '',
    img: laguna,
    bigImg: null,
    platformLogo: null,
    available: false
  },
  {
    id: 'weighing',
    descriptionId: 'weighing_description',
    textId: '',
    img: weighing,
    bigImg: null,
    platformLogo: null,
    available: false
  },
]
