import React from 'react'
import {motion} from "framer-motion"

const RequisitesPopupSubstr = ({handleClosePopup}) => {
  return (
    <motion.div
      onClick={handleClosePopup}
      className={'requisites-popup-substr'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: {duration: 0.2} }}
      exit={{ opacity: 0, transition: {duration: 0.2} }}
    />
  )
}

export default RequisitesPopupSubstr
