import React, {useEffect, useState} from 'react'
import Container from "../../components/Container";
import BaseHeadline from "../../components/BaseHeadline";
import {FormattedMessage} from "react-intl";
import {RELEASES_LIST} from "./const";
import ReleaseCard from "./components/ReleaseCard";
import {createPortal} from "react-dom";
import ReleaseModal from "./components/ReleaseModal";
import {AnimatePresence} from "framer-motion";
import {appSelectors} from "../../models/appModels";

const ReleasesScreen = () => {
  const [mounted, setMounted] = useState(false)
  const [releaseInfo, setReleaseInfo] = useState(null)

  const setIsBlockScroll = appSelectors.isBlockScroll.useSetValue()

  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  const handleClickRelease = (releaseData) => {
    if (!releaseData?.available) return
    setIsBlockScroll(true)
    document.documentElement.style.cssText = 'overflow: hidden;';
    setReleaseInfo(releaseData)
  }

  const handleExitComplete = () => {
    setIsBlockScroll(false)
    document.documentElement.style.cssText = 'overflow: unset;';
    return null
  }

  return (
    <Container customClass="releases">
      <BaseHeadline>
        <FormattedMessage id={'releases'}/>
      </BaseHeadline>

      <div className="releases__list">
        {RELEASES_LIST.map(release => {
          return (
            <ReleaseCard
              key={release.id}
              releaseInfo={release}
              onClick={() => handleClickRelease(release)}
            />
          )
        })}
      </div>

      {mounted && createPortal(
        <AnimatePresence
          initial={false}
          mode={'wait'}
          onExitComplete={handleExitComplete}
        >
          {releaseInfo
            ? <ReleaseModal
              releaseInfo={releaseInfo}
              setReleaseInfo={setReleaseInfo}
            />
            : null}
        </AnimatePresence>,
        document.getElementById('contentWrapper')
      )}
    </Container>
  )
}

export default ReleasesScreen
