import Image from "next/image";
import requisites from "../public/images/contacts/requisites.svg";
import close from "../public/images/releases/close.svg";
import completed from '../public/images/contacts/completed.svg'
import React, {useEffect, useMemo, useRef, useState} from "react";
import {motion} from "framer-motion"
import useWindowSize from "../hooks/windowSizeHook";
import {
  ADDRESS,
  BANK_INFO,
  CORRESPONDENT_ACCOUNT,
  CURRENT_ACCOUNT,
  TAX_INFO, TAX_INFO_2
} from "../screens/contacts_screen/const";

const RequisitesPopup = ({isOpenPopup, handleOpenPopup, handleClosePopup}) => {
  const ref = useRef(null)
  const windowSize = useWindowSize()
  const [isShowAlert, setIsShowAlert] = useState(false)

  useEffect(() => {
    let timer = null

    if (isOpenPopup) {
      setIsShowAlert(true)
      timer = setTimeout(() => setIsShowAlert(false), 2000)
    } else {
      setIsShowAlert(false)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [isOpenPopup])

  const [popupSize, setPopupSize] = useState({
    width: 0,
    height: 0
  })

  const variants = useMemo(() => {
    return {
      active: {
        cursor: 'unset',
        y: -(windowSize.height - popupSize.height) / 2 - windowSize.height + 'px',
        x: (windowSize.width - popupSize.width) / 2 + 'px',
        transition: {
          duration: 0.2,
        },
        ease: 'ease-in-out'
      },
      inactive: {
        cursor: 'pointer',
        y: popupSize.height * 0.894 - windowSize.height + 'px',
        x: (windowSize.width - popupSize.width) / 2 + 'px',
        transition: {
          duration: 0.2,
        },
        ease: 'ease-out'
      },
    }
  }, [popupSize, windowSize])

  const btnVariants = {
    active: {
      rotate: 0,
      transition: {
        duration: 0.2,
      },
      ease: 'ease-in-out'
    },
    inactive: {
      rotate: 45,
      transition: {
        duration: 0.2,
      },
      ease: 'ease-in-out'
    }
  }

  const alertVariants = useMemo(() => {
    return {
      active: {
        y: -popupSize.height*0.04 + 'px',
        x: popupSize.width * 0.06 + 'px',
        opacity: 1,
        transition: {
          duration: 0.2,
          delay: 0.2
        },
        ease: 'ease-in-out'
      },
      inactive: {
        y: 0 + 'px',
        x: popupSize.width * 0.06 + 'px',
        opacity: 0,
        transition: {
          duration: 0.2,
        },
        ease: 'ease-in-out'
      }
    }
  }, [popupSize, windowSize])

  useEffect(() => {
    if (ref?.current) {
      setPopupSize({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight
      });
    }
  }, [ref, windowSize])

  const onClickBtn = (isBody = false) => {
    if (isOpenPopup) {
      if (isBody) return
      handleClosePopup()
    } else {
      handleOpenPopup()
    }
  }

  const lineBreakToBr = (str) => str.split('\n').map(item => {
    return <span>{item}<br/></span>
  })

  return (
    <motion.div
      ref={ref}
      key={'requisites-popup'}
      className={'requisites-popup'}
      variants={variants}
      initial={'inactive'}
      animate={
        isOpenPopup
          ? 'active'
          : 'inactive'
      }
      // whileHover={isOpenPopup ? {} : {
      //   cursor: 'pointer',
      //   y: popupSize.height * 0.814 - windowSize.height + 'px',
      //   x: (windowSize.width - popupSize.width) / 2 + 'px',
      //   transition: {
      //     duration: 0.2,
      //   },
      //   ease: 'ease-in-out'
      // }}
      onClick={() => onClickBtn(true)}
    >
      <div className="requisites-popup__img">
        <Image
          src={requisites}
          alt={'requisites'}
        />
      </div>

      <div className="requisites-popup__header">
        <h3>реквизиты</h3>

        <motion.div
          variants={btnVariants}
          initial={'inactive'}
          animate={isOpenPopup ? 'active' : 'inactive'}
          className={'requisites-popup__btn'}
          onClick={() => onClickBtn(false)}
        >
          <Image
            src={close}
            alt={'close'}
          />
        </motion.div>
      </div>

      <div className="requisites-popup__address">
        <span>{ADDRESS}</span>
      </div>

      <div className="requisites-popup__accounts">
        <div className="requisites-popup__current-account">
            <span>
              {lineBreakToBr(CURRENT_ACCOUNT)}
            </span>
        </div>

        <div className="requisites-popup__correspondent-account">
            <span>
              {lineBreakToBr(CORRESPONDENT_ACCOUNT)}
            </span>
        </div>
      </div>

      <div className="requisites-popup__bank-info">
          <span>
            {lineBreakToBr(BANK_INFO)}
          </span>
      </div>

      <div className="requisites-popup__tax-info">
        <div>
          <span>
            {lineBreakToBr(TAX_INFO)}
          </span>
        </div>

        <div>
            <span>
              {lineBreakToBr(TAX_INFO_2)}
            </span>
        </div>
      </div>

      <motion.div
        className="requisites-popup__buffer-save"
        variants={alertVariants}
        initial={'inactive'}
        animate={isShowAlert ? 'active' : 'inactive'}
      >
        <div><Image src={completed} alt={'completed'}/></div>
        <span>Скопировано в буфер</span>
      </motion.div>
    </motion.div>
  )
}

export default RequisitesPopup
