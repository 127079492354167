import React, {useMemo} from 'react'
import lines from "../../../public/images/main/lines.svg";
import Image from "next/image";
import Constants from "./const";
import useWindowSize from "../../../hooks/windowSizeHook";
import {localeSelectors} from "../../../models/locale";

const Lines = (
  {
    substrRef,
    elementRef,
  }) => {
  const locale = localeSelectors.useValue()
  const size = useWindowSize()

  const params = useMemo(() => {
    const substrRect = substrRef.current?.getBoundingClientRect()
      ?? {
        top: 0,
        left: 0
      }
    const substrWidth = substrRef.current?.offsetWidth ?? 0
    const substrHeight = substrRef.current?.offsetHeight ?? 0
    const windowYOffset = typeof window === 'undefined'
      ? 0
      : window.scrollY
    const linesOffsetTop = substrRect.top + windowYOffset - substrHeight * Constants.linesHeightFactor
    const linesOffsetLeft = substrRect.left - substrWidth * Constants.linesWidthFactor
    const linesWidth = Constants.linesLayoutWidth/Constants.substrLayoutWidth * substrWidth

    return {
      linesWidth,
      linesOffsetTop,
      linesOffsetLeft
    }
  }, [size, substrRef, locale])


  return (
    <div
      ref={elementRef}
      className={'lines'}
      style={{
        width: `${params.linesWidth}px`,
        top: `${params.linesOffsetTop}px`,
        left: `${params.linesOffsetLeft}px`
      }}
    >
      <Image
        src={lines}
        alt={'lines'}
      />
    </div>
  )
}

export default Lines
