import A from '../public/icons/preload-icons/A.svg'
import B from '../public/icons/preload-icons/B.svg'
import C from '../public/icons/preload-icons/C.svg'
import D from '../public/icons/preload-icons/D.svg'
import E from '../public/icons/preload-icons/E.svg'
import F from '../public/icons/preload-icons/F.svg'
import G from '../public/icons/preload-icons/G.svg'
import I from '../public/icons/preload-icons/I.svg'
import K from '../public/icons/preload-icons/K.svg'
import M from '../public/icons/preload-icons/M.svg'
import O from '../public/icons/preload-icons/O.svg'
import Q from '../public/icons/preload-icons/Q.svg'
import R from '../public/icons/preload-icons/R.svg'
import T from '../public/icons/preload-icons/T.svg'
import V from '../public/icons/preload-icons/V.svg'
import X from '../public/icons/preload-icons/X.svg'
import Y from '../public/icons/preload-icons/Y.svg'
import Z from '../public/icons/preload-icons/Z.svg'

export const LETTERS = [
  [
    {
      id: 'A',
      img: A
    },
    {
      id: 'E',
      img: E
    },
    {
      id: 'F',
      img: F
    },
    {
      id: 'Q',
      img: Q
    },
    {
      id: 'R',
      img: R
    },
  ],
  [
    {
      id: 'O',
      img: O
    },
    {
      id: 'G',
      img: G
    },
    {
      id: 'K',
      img: K
    },
    {
      id: 'M',
      img: M
    },
    {
      id: 'I',
      img: I
    },
  ],
  [
    {
      id: 'T',
      img: T
    },
    {
      id: 'D',
      img: D
    },
    {
      id: 'X',
      img: X
    },
    {
      id: 'A',
      img: A
    },
    {
      id: 'C',
      img: C
    },
  ],
  [
    {
      id: 'K',
      img: K
    },
    {
      id: 'V',
      img: V
    },
    {
      id: 'T',
      img: T
    },
    {
      id: 'M',
      img: M
    },
    {
      id: 'Y',
      img: Y
    },
  ],
  [
    {
      id: 'X',
      img: X
    },
    {
      id: 'C',
      img: C
    },
    {
      id: 'O',
      img: O
    },
    {
      id: 'F',
      img: F
    },
    {
      id: 'I',
      img: I
    },
  ],
  [
    {
      id: 'T',
      img: T
    },
    {
      id: 'G',
      img: G
    },
    {
      id: 'Z',
      img: Z
    },
    {
      id: 'B',
      img: B
    },
    {
      id: 'D',
      img: D
    },
  ],
]