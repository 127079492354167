import React from 'react'
import Container from "../../components/Container";
import BaseHeadline from "../../components/BaseHeadline";
import BaseTitle from "../../components/BaseTitle";
import FormattedMsgWithLineBreak from "../../components/FormattedMsgWithLineBreak";
import BaseCaption from "../../components/BaseCaption";
import {TEAM_LIST} from "./const";
import MemberCard from "./components/MemberCard";
import {localeSelectors} from "../../models/locale";

const TeamScreen = () => {
  const locale = localeSelectors.useValue()

  return (
    <Container id={'team'} customClass={`team ${locale === 'ru' ? 'team_ru' : ''}`}>
      <div className="team__text-block">
        <BaseHeadline>
          <FormattedMsgWithLineBreak id={'team'}/>
        </BaseHeadline>
        <div className="team__title-block">
          <BaseTitle>
            <FormattedMsgWithLineBreak
              id={'team_section_title'}
            />
          </BaseTitle>

          <BaseCaption isSmall>
            <FormattedMsgWithLineBreak
              id={'team_section_caption'}
            />
          </BaseCaption>
        </div>
      </div>

      <div className="team__team-block">
        <div className="team__list">
          {TEAM_LIST.map(member => {
            return <MemberCard
              key={member.nameId}
              member={member}
            />
          })}
        </div>
      </div>
    </Container>
  )
}

export default TeamScreen
