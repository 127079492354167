import stack1 from '../../public/images/stack/stack_1.svg'
import stack2 from '../../public/images/stack/stack_2.svg'
import stack3 from '../../public/images/stack/stack_3.svg'
import stack4 from '../../public/images/stack/stack_4.svg'
import stack5 from '../../public/images/stack/stack_5.svg'
import stack6 from '../../public/images/stack/stack_6.svg'
import stack7 from '../../public/images/stack/stack_7.svg'
import stack8 from '../../public/images/stack/stack_8.svg'
import stack9 from '../../public/images/stack/stack_9.svg'

export const STACKS_LIST = [
  {
    textId: 'stack_1_descr',
    img: stack1
  },
  {
    textId: 'stack_2_descr',
    img: stack2
  },
  {
    textId: 'stack_3_descr',
    img: stack3
  },
  {
    textId: 'stack_4_descr',
    img: stack4
  },
  {
    textId: 'stack_5_descr',
    img: stack5
  },
  {
    textId: 'stack_6_descr',
    img: stack6
  },
  {
    textId: 'stack_7_descr',
    img: stack7
  },
  {
    textId: 'stack_8_descr',
    img: stack8
  },
  {
    textId: 'stack_9_descr',
    img: stack9
  }
]

const TOOLS_TEXT_COLORS = ['#DACAA4', '#FFFFFF', '#A4A4A4']
const TOOLS_BACKGROUND_COLORS = ['#353535', '#3C3C3C', '#2C2C2C']
const TOOLS_LIST = [
  {
    text: 'Gitlab CI/CD',
  },
  {
    text: 'k8s',
  },
  {
    text: 'S3',
  },
  {
    text: 'Hadoop',
  },
  {
    text: 'RabbitMQ',
  },
  {
    text: 'Hive',
  },
  {
    text: 'ClickHouse',
  },
  {
    text: 'PostgreSQL',
  },
  {
    text: 'kerberos',
  },
  {
    text: 'LDAP',
  },
  {
    text: 'Python3',
  },
  {
    text: 'Vue',
  },
  {
    text: 'Vuex',
  },
  {
    text: 'dramatiq',
  },
  {
    text: 'Django',
  },
  {
    text: 'Nuxt',
  },
  {
    text: 'Redis',
  },
  {
    text: 'ect.',
  },
]

export function getToolListWithRandomColors() {
  let lastColorIndex = null
  let lastBackgroundIndex = null
  function getRandomFromList(list, isColor = false) {
    const randomIndex = Math.floor(Math.random() * list.length)
    const lastIndex = isColor
      ? lastColorIndex
      : lastBackgroundIndex

    if (randomIndex == lastIndex) {
      return getRandomFromList(list, isColor)
    } else {
      if (isColor) {
        lastColorIndex = randomIndex
      } else {
        lastBackgroundIndex = randomIndex
      }
      return list[randomIndex]
    }
  }

  return TOOLS_LIST.map(({text}) => {
    return {
      text,
      textColor: getRandomFromList(TOOLS_TEXT_COLORS, true),
      backgroundColor: getRandomFromList(TOOLS_BACKGROUND_COLORS)
    }
  })
}

export const TOOLS_COLOR_LIST = getToolListWithRandomColors()
