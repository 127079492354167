import React, {useEffect, useState} from 'react'
import Container from "../../components/Container";
import bgImg from '../../public/images/contacts/bg.svg';
import Image from "next/image";
import BaseTitle from "../../components/BaseTitle";
import FormattedMsgWithLineBreak from "../../components/FormattedMsgWithLineBreak";
import BaseCaption from "../../components/BaseCaption";
import {FormattedMessage} from "react-intl";
import {ALL_INFO, MAIL} from "./const";
import BaseHeadline from "../../components/BaseHeadline";
import RequisitesPopupSubstr from "../../components/RequisitesPopupSubstr";
import {AnimatePresence} from "framer-motion";
import RequisitesPopup from "../../components/RequisitesPopup";
import {appSelectors} from "../../models/appModels";
import {localeSelectors} from "../../models/locale";

const ContactsScreen = () => {
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [mounted, setMounted] = useState(false)
  const setIsBlockScroll = appSelectors.isBlockScroll.useSetValue()
  const locale = localeSelectors.useValue()

  useEffect(() =>  {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  const copyToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleClosePopup = () => {
    setIsOpenPopup(false)
    setIsBlockScroll(false)
  }

  const handleOpenPopup = async () => {
    setIsBlockScroll(true)
    setIsOpenPopup(true)
    await copyToClipboard(ALL_INFO)
  }

  return (
    <>
      <Container customClass={'contacts'}>
        <div className="contacts__content">
          <BaseTitle>
            <FormattedMsgWithLineBreak id={'contacts_title'}/>
          </BaseTitle>

          <div className="contacts__footer">
            <div className="contacts__footer-left">
              <BaseCaption isSmall>
                <FormattedMessage id={'contact_caption'}/>
              </BaseCaption>

              <BaseTitle>
                <a href={`mailto:${MAIL}`}>
                  {MAIL}
                </a>
              </BaseTitle>
            </div>

            <BaseHeadline>
              <FormattedMessage id={'contacts_copyright'}/>
            </BaseHeadline>
          </div>
        </div>

        <div className="contacts__bg">
          <Image
            src={bgImg}
            alt="mamont"
          />
        </div>

      </Container>

      {locale === 'ru' &&
        <>
          {mounted && <AnimatePresence
            initial={false}
            mode={'wait'}
            onExitComplete={() => null}
          >
            {
              isOpenPopup
                ? <RequisitesPopupSubstr handleClosePopup={handleClosePopup}/>
                : null
            }
          </AnimatePresence>}

          <RequisitesPopup
            isOpenPopup={isOpenPopup}
            handleClosePopup={handleClosePopup}
            handleOpenPopup={handleOpenPopup}
          />
        </>
      }
    </>
  )
}

export default ContactsScreen