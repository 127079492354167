import React, {useCallback, useEffect, useRef, useState} from "react";
import Stone from "./Stone";
import Fire from "./Fire";
import Lines from "./Lines";

const AnimatedElements = (
  {
    substrRef,
    duration,
    mainScreenHeight,
  }) => {
  const requestRef = useRef(null)
  const stoneRef = useRef(null)
  const fireRef = useRef(null)
  const linesRef = useRef(null)
  const [isMounted, setIsMounted] = useState(false)

  const animate = useCallback(() => {
    const position = window?.scrollY ?? 0

    if (stoneRef.current) {
      const rotate = position <= mainScreenHeight
        ? -30 * (position < 0 ? 0 : position)/mainScreenHeight
        : -30
      const transformOffset = position <= duration
        ? 0
        : -(position - duration)

      stoneRef.current.style.transform = `translate3d(0, ${transformOffset}px, 0) rotate(${rotate}deg)`
    }

    if (fireRef.current) {
      const opacity = position <= mainScreenHeight
        ? position/mainScreenHeight
        : 1
      const transformOffset = position <= duration
        ? 0
        : -(position - duration)

      fireRef.current.style.transform = `translate3d(0, ${transformOffset}px, 0) rotate(-15deg)`
      fireRef.current.style.opacity = opacity.toString()
    }

    if (linesRef.current) {
      const isFirstPosition = position <= mainScreenHeight
      const isSecondPosition = !isFirstPosition && position <= duration

      if (isFirstPosition) {
        const transformOffsetTop = mainScreenHeight - position
        const transformOffsetLeft = mainScreenHeight - position
        linesRef.current.style.transform = `translate3d(${-transformOffsetLeft}px, ${transformOffsetTop}px, 0) rotate(-30deg)`
      } else if (isSecondPosition) {
        linesRef.current.style.transform = `translate3d(0, 0, 0) rotate(-30deg)`
      } else {
        const transformOffset = position <= duration
          ? 0
          : -(position - duration)
        linesRef.current.style.transform = `translate3d(0, ${transformOffset}px, 0) rotate(-30deg)`
      }
    }
    requestRef.current = requestAnimationFrame(animate);
  }, [mainScreenHeight, duration])

  useEffect(() => {
    if (isMounted) {
      requestRef.current = requestAnimationFrame(animate);

      return () => cancelAnimationFrame(requestRef.current);
    } else {
      setIsMounted(true)
    }
  }, [isMounted, animate]);

  return (
    <>
      <Stone
        substrRef={substrRef}
        elementRef={stoneRef}
      />

      <Fire
        substrRef={substrRef}
        elementRef={fireRef}
      />

      <Lines
        substrRef={substrRef}
        elementRef={linesRef}
      />
    </>
  )
}

export default AnimatedElements
