import team1 from '../../public/images/team/roma.svg'
import team2 from '../../public/images/team/dima.svg'
import team3 from '../../public/images/team/killreal.svg'

export const TEAM_LIST = [
  {
    nameId: 'team_member_name_1',
    infoId: 'team_member_info_1',
    img: team1
  },
  {
    nameId: 'team_member_name_2',
    infoId: 'team_member_info_2',
    img: team2
  },
  {
    nameId: 'team_member_name_3',
    infoId: 'team_member_info_3',
    img: team3
  },
]
