import React, {memo, useState} from 'react'
import Container from "../../components/Container";
import stackImg from '../../public/images/stack/stack_img.svg'
import lightRight from '../../public/images/stack/light-right.svg'
import Image from "next/image";
import BaseHeadline from "../../components/BaseHeadline";
import {FormattedMessage} from "react-intl";
import BaseTitle from "../../components/BaseTitle";
import BaseCaption from "../../components/BaseCaption";
import BaseButton from "../../components/BaseButton";
import StackCard from "./components/StackCard";
import {STACKS_LIST, TOOLS_COLOR_LIST} from "./const";
import {motion} from "framer-motion"
import ToolCard from "../../components/ToolCard";
import {localeSelectors} from "../../models/locale";
import FormattedMsgWithLineBreak from "../../components/FormattedMsgWithLineBreak";

const tabContentVariant = {
  active: {
    display: "block",
  },
  inactive: {
    display: "none"
  }
};

const childVariant = {
  active: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
    ease: 'ease-in-out'
  },
  inactive: {
    opacity: 0,
    transition: {
      duration: 0.2
    },
    ease: 'ease-in-out'
  }
};

const StackScreen = () => {
  const [isFirstTabActive, setIsFirstTabActive] = useState(true)
  const locale = localeSelectors.useValue()

  const MotionStackCard = motion(StackCard)
  const MotionToolCard = motion(ToolCard)

  return (
    <Container id={'stack'} customClass={`stack ${locale === 'ru' ? 'stack_ru' : ''}`}>
      <div className="stack__img">
        <Image
          src={stackImg}
          alt={'stack-img'}
        />
      </div>

      <div className="stack__light-right"/>
      <div className="stack__light-left"/>

      <div className="stack__wrapper">
        <BaseHeadline>
          <FormattedMessage id={'stack'}/>
        </BaseHeadline>

        <div className="stack__text-block">
          <BaseTitle>
            <FormattedMessage id={'stack_section_title_1'}/>
            <br/>
            <FormattedMessage id={'stack_section_title_2'}/>
          </BaseTitle>

          <BaseCaption isSmall>
            <FormattedMsgWithLineBreak id={'stack_section_caption'}/>
          </BaseCaption>
        </div>

        <div className="stack__tabs">
          <BaseButton
            onClick={() => setIsFirstTabActive(true)}
            classes={isFirstTabActive ? '' : 'inactive'}
          >
            <FormattedMessage id={'stack_section_tab_1'}/>
          </BaseButton>

          <BaseButton
            onClick={() => setIsFirstTabActive(false)}
            classes={!isFirstTabActive ? '' : 'inactive'}
          >
            <FormattedMessage id={'stack_section_tab_2'}/>
          </BaseButton>
        </div>

        <motion.div
          variants={tabContentVariant}
          animate={isFirstTabActive ? 'active' : 'inactive'}
          initial="inactive"
        >
          <div className="stack__stacks-grid">
            {STACKS_LIST.map(stack => {
              return <MotionStackCard
                key={stack.textId}
                variants={childVariant}
                stackInfo={stack}
              />
            })}
          </div>
        </motion.div>

        <motion.div
          variants={tabContentVariant}
          animate={!isFirstTabActive ? 'active' : 'inactive'}
          initial="inactive"
        >
          <div className="stack__tool-grid">
            {TOOLS_COLOR_LIST.map(tool => {
              return <MotionToolCard
                key={tool.text}
                variants={childVariant}
                toolInfo={tool}
              />
            })}
          </div>
        </motion.div>
      </div>
    </Container>
  )
}

export default memo(StackScreen, () => true)
