export default class Constants {
  // substr
  static substrLayoutWidth = 500

  // stone
  static stoneLayoutWidth = 710
  static stoneHeightFactor = 0.265
  static stoneWidthFactor = 0.17

  // fire
  static fireLayoutWidth = 1439
  static fireHeightFactor = 0.62
  static fireWidthFactor = 1.04

  // lines
  static linesLayoutWidth = 1537
  static linesHeightFactor = -0.25
  static linesWidthFactor = 1.52
  static linesShift = 300
}
