import React from 'react'
import {motion} from "framer-motion"
import Image from "next/image";
import BaseTitle from "../../../components/BaseTitle";
import FormattedMsgWithLineBreak from "../../../components/FormattedMsgWithLineBreak";
import BaseCaption from "../../../components/BaseCaption";
import BaseButton from "../../../components/BaseButton";
import close from '../../../public/images/releases/close.svg'

const ReleaseModal = ({releaseInfo, setReleaseInfo}) => {
  const open = {
    hidden: {
      x: 1268/1920 * 100 + 'vw',
      ease: 'ease-in-out'
    },
    visible: {
      x: '0',
      transition: {
        duration: 0.1,
        delay: 0.1,
      },
      ease: 'ease-in-out'
    },
    exit: {
      x: 1268/1920 * 100 + 'vw',
      ease: 'ease-in-out'
    }
  }

  const handleCloseModal = () => setReleaseInfo(null)

  return (
    <motion.div
      className={'release-modal'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: {duration: 0.1} }}
      exit={{ opacity: 0, transition: {delay: 0.1} }}
      onClick={handleCloseModal}
    >
     <motion.div
       className={'release-modal__wrapper'}
       variants={open}
       initial={'hidden'}
       animate={'visible'}
       exit={'exit'}
       onClick={(e) => e.stopPropagation()}
     >
       <BaseButton
         isRound
         onClick={handleCloseModal}
       >
         <div className="release-modal__close">
           <Image
             src={close}
             alt={'close'}
           />
         </div>
       </BaseButton>

       <div
         className="release-modal__scroll-wrapper"
       >
         <div className="release-modal__img">
           <Image
             src={releaseInfo?.bigImg}
             alt={'release'}
           />
         </div>

         <div className="release-modal__content">
           <BaseTitle isMedium>
             <FormattedMsgWithLineBreak id={releaseInfo?.descriptionId}/>
           </BaseTitle>

           <BaseCaption>
             <FormattedMsgWithLineBreak id={releaseInfo?.textId}/>
           </BaseCaption>
         </div>
       </div>
     </motion.div>
    </motion.div>
  )
}

export default ReleaseModal
