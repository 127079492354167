import React from "react";
import BaseCaption from "../../../components/BaseCaption";
import Image from "next/image";
import FormattedMsgWithLineBreak from "../../../components/FormattedMsgWithLineBreak";

const StackCard = React.forwardRef(({stackInfo}, ref) => {
  return (
    <div className="stack-card" ref={ref}>
      <div className="stack-card__img">
        <Image
          src={stackInfo.img}
          alt={'stack'}
        />
      </div>

      <div className="stack-card__text">
        <BaseCaption isSmall>
          <FormattedMsgWithLineBreak
            id={stackInfo.textId}
          />
        </BaseCaption>
      </div>
    </div>
  )
})

export default StackCard
