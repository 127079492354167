import intellect from '../../public/images/advantages/intellect.svg'
import conscientiousness from '../../public/images/advantages/conscientiousness.svg'
import clarity from '../../public/images/advantages/clarity.svg'

export const ADVANTAGES_LIST = [
  {
    id: 'intellect',
    titleId: 'intellect',
    descrId: 'intellect_description',
    img: intellect
  },
  {
    id: 'conscientiousness',
    titleId: 'conscientiousness',
    descrId: 'conscientiousness_description',
    img: conscientiousness
  },
  {
    id: 'clarity',
    titleId: 'clarity',
    descrId: 'clarity_description',
    img: clarity
  },
]
