import Head from 'next/head'
import TeamScreen from '../screens/team_screen/TeamScreen'
import Preloader from '../components/Preloader'
import React, {useEffect, useLayoutEffect, useMemo, useRef} from 'react'
import {getScrollbarWidth, setVHUnit} from "../helpers/helpers";
import {
  isActiveMainAnimateSelectors,
} from "../models/isActiveMainAnimate";
import {
  isActiveContentAnimateSelectors,
} from "../models/isActiveContentAnimation";
import {Transition} from "react-transition-group";
import {appSelectors} from "../models/appModels";
import Header from "../components/Header";
import ScrollScreens from "../screens/scroll_screens/ScrollScreens";
import StackScreen from "../screens/stack_screen/StackScreen";
import AdvantagesScreen from "../screens/advantages_screen/AdvantagesScreen";
import ReleasesScreen from "../screens/releases_screen/ReleasesScreen";
import useWindowSize from "../hooks/windowSizeHook";
import ContactsScreen from "../screens/contacts_screen/ContactsScreen";

export default function IndexPage() {
  const windowSize = useWindowSize()

  //content
  // const setAboutScreenContent = aboutScreenSelectors.useSetValue()
  // const setBtnsTextContent = btnsTextSelectors.useSetValue()
  // const setContactsScreenContent = contactsScreenSelectors.useSetValue()
  // const setFooterContentContent = footerContentSelectors.useSetValue()
  // const setLinksContent = linksSelectors.useSetValue()
  // const setMainScreenContent = mainScreenSelectors.useSetValue()
  // const setNavLinksContent = navLinksSelectors.useSetValue()
  // const setMenuContent = menuSelectors.useSetValue()
  // const setPartnersContent = partnersSelectors.useSetValue()
  // const setPreloaderContent = preloaderSelectors.useSetValue()
  // const setReasonsListContent = reasonsListSelectors.useSetValue()
  // const setReasonsScreenContent = reasonsScreenSelectors.useSetValue()
  // const setStuffsListContent = stuffsListSelectors.useSetValue()
  // const setStuffsScreenContent = stuffsScreenSelectors.useSetValue()
  // const setTeamScreenContent = teamScreenSelectors.useSetValue()
  // const setBackCallContent = backCallSelectors.useSetValue()

  // const setIsActiveTeamAnimate = isActiveTeamAnimateSelectors.useSetValue()
  // const locale = localeSelectors.useValue()
  const [isActiveContentAnimate, setIsActiveContentAnimate] = isActiveContentAnimateSelectors.useState()
  const [isActiveMainAnimate, setIsActiveMainAnimate] = isActiveMainAnimateSelectors.useState()
  // const isOpenMenu = isOpenMenuSelectors.useValue()
  // const toggleMenu = isOpenMenuSelectors.useToggleValue()

  // const [isSmoothLink, setIsSmoothLink] = appSelectors.isSmoothLink.useState()
  // const [startTouchX, setStartTouchX] = appSelectors.startTouchX.useState()
  const [isPreloaderShow, setIsPreloaderShow] = appSelectors.isPreloaderShow.useState()
  const [isBlockScroll, setIsBlockScroll] = appSelectors.isBlockScroll.useState()
  // const [scrollPos, setScrollPos] = appSelectors.scrollPos.useState()
  const [isHidePreloader, setIsHidePreloader] = appSelectors.isHidePreloader.useState()
  const setIsLettersMoving = appSelectors.isLettersMoving.useSetValue()

  // const content = useRef()
  const contentWrapper = useRef(null)
  // const teamBlock = useRef()
  // const mainAnimationItem = useRef()

  // const transitionDurationContent = useMemo(() => {
  //   return windowSize.width >= 1024
  //     ? 1000
  //     : windowSize.width >= 576
  //       ? 800
  //       : 500
  // }, [windowSize])
  //
  const blockScrollClass = useMemo(() => {
    return isBlockScroll
      ? 'pre-open'
      : ''
  }, [isBlockScroll])

  const scrollWidth = useMemo(() => {
    return isBlockScroll
      ? getScrollbarWidth() + 'px'
      : '0'
  }, [windowSize, isBlockScroll])

  useEffect(() => {
    setVHUnit()

    // if (content.current && contentWrapper.current) {
    //   if (content.current.clientHeight - window.innerHeight - contentWrapper.current.scrollTop > 1) setIsActiveContentAnimate(false)
    //
    //   if (contentWrapper.current.scrollTop !== 0) setIsActiveMainAnimate(true)
    // }
  }, [windowSize])

  // useEffect(() => {
  //   setPageData(locale)
  // }, [locale])

  useLayoutEffect(() => {
    if (window) {
      window.scrollTo(0, 0)
    }
  }, [])

  useEffect(() => {
    onLoad()
    const handleSetVHUnit = () => setTimeout(setVHUnit, 100)

    window.addEventListener('orientationchange', handleSetVHUnit)

    return () => {
      window.removeEventListener('orientationchange', handleSetVHUnit)
    }
  }, [])

  useEffect(() => {
    if (isBlockScroll) {
      document.documentElement.style.cssText = `overflow: hidden; padding-right: ${scrollWidth};`;
    } else {
      document.documentElement.style.cssText = 'padding-right: 0;';
    }
  }, [scrollWidth, isBlockScroll])


  const onLoad = () => {
    new Promise((resolve) => {
      setTimeout(() => resolve(1), 1500)
    }).then(() => {
      setIsLettersMoving(true)

      return new Promise((resolve) => {
        if ((contentWrapper.current?.scrollTop ?? 0) > 0) {
          setIsActiveMainAnimate(true)
        }

        setTimeout(() => resolve(1), 3000)
      })
    }).then(() => {
      window?.scrollTo(0, 0)
      setIsHidePreloader(true)
      setIsPreloaderShow(false)
      setIsBlockScroll(false)

      // if (contentWrapper.current?.scrollTop > 0) {
      //   setIsBlockScroll(false)
      // }
    })
  }

  // const onScrollContentWrapper = () => {
  //   const diffBetweenContentHeightContentScroll = content.current.clientHeight - window.innerHeight - contentWrapper.current.scrollTop
  //
  //   if (isActiveMainAnimate && contentWrapper.current.scrollTop <= 0) {
  //     setIsActiveMainAnimate(false)
  //     setIsBlockScroll(true)
  //   } else if (
  //     diffBetweenContentHeightContentScroll <= 2 &&
  //     scrollPos - contentWrapper.current.scrollTop < 0 &&
  //     !isActiveContentAnimate
  //   ) {
  //     setIsActiveContentAnimate(true)
  //     setIsBlockScroll(true)
  //   }
  //
  //   if (teamBlock.current.getBoundingClientRect().top/window.innerHeight <= 0.2) {
  //     setIsActiveTeamAnimate(true)
  //   }
  //
  //   setScrollPos(contentWrapper.current.scrollTop)
  // }

  // const toggleAnimate = (delta) => {
  //   const diffBetweenContentHeightContentScroll = content.current.clientHeight - window.innerHeight - contentWrapper.current.scrollTop
  //
  //   if (contentWrapper.current.scrollTop <= 0) {
  //     if (delta > 0) {
  //       setIsActiveMainAnimate(true)
  //
  //       if (isOpenMenu) toggleMenu()
  //     } else if (delta < 0) {
  //       setIsActiveMainAnimate(false)
  //       setIsBlockScroll(true)
  //     }
  //   } else if (diffBetweenContentHeightContentScroll <= 2) {
  //     if (delta > 0) {
  //       setIsActiveContentAnimate(true)
  //
  //       setIsBlockScroll(true)
  //     } else if (delta < 0) {
  //       setIsActiveContentAnimate(false)
  //     }
  //   }
  // }

  // const onWheel = (e) => {
  //   if (isSmoothLink) {
  //     e.preventDefault()
  //     e.stopPropagation()
  //
  //     return
  //   }
  //
  //   if (isPreloaderShow || isOpenMenu) return
  //
  //   toggleAnimate(e.deltaY)
  // }

  // const onTouchStart = (e) => {
  //   if (isOpenMenu) return
  //
  //   setStartTouchX(e.changedTouches[0].clientY)
  // }
  //
  // const onTouchEnd = (e) => {
  //   const delta = startTouchX -  e.changedTouches[0].clientY
  //
  //   if (isOpenMenu || isSmoothLink) return
  //
  //   toggleAnimate(delta)
  // }

  // const handleTransitionEnd = (e) => {
  //   if (isSmoothLink && e.target === content.current) setIsSmoothLink(false)
  //
  //   if (isPreloaderShow || isSmoothLink) return
  //
  //   if (e.target === content.current) {
  //     if (isActiveContentAnimate) {
  //       setStartTouchX(0)
  //       setIsBlockScroll(true)
  //     } else {
  //       setIsBlockScroll(false)
  //     }
  //   } else if (e.target === mainAnimationItem.current) {
  //     if (isActiveMainAnimate) {
  //       setIsBlockScroll(false)
  //     } else {
  //       contentWrapper.current.scrollTo(0, 0)
  //       setStartTouchX(0)
  //       setIsBlockScroll(true)
  //     }
  //   }
  // }

  // const onTouchMove = (e) => {
  //   if (isSmoothLink) {
  //     e.preventDefault()
  //     e.stopPropagation()
  //
  //     return
  //   }
  // }


  // const setPageData = (locale) => {
  //   setPartnersContent(PAGE_DATA[locale].partnersList)
  //   setMainScreenContent(PAGE_DATA[locale].screensContent.main)
  //   setReasonsScreenContent(PAGE_DATA[locale].screensContent.reasons)
  //   setReasonsListContent(PAGE_DATA[locale].reasonsList)
  //   setBtnsTextContent(PAGE_DATA[locale].btnsText)
  //   setStuffsScreenContent(PAGE_DATA[locale].screensContent.stuffs)
  //   setStuffsListContent(PAGE_DATA[locale].stuffsList)
  //   setTeamScreenContent(PAGE_DATA[locale].screensContent.team)
  //   setNavLinksContent(PAGE_DATA[locale].navLinks)
  //   setLinksContent(PAGE_DATA[locale].links)
  //   setContactsScreenContent(PAGE_DATA[locale].screensContent.contacts)
  //   setAboutScreenContent(PAGE_DATA[locale].screensContent.about)
  //   setFooterContentContent(PAGE_DATA[locale].footer)
  //   setPreloaderContent(PAGE_DATA[locale].preloader)
  //   setMenuContent(PAGE_DATA[locale].menu)
  //   setBackCallContent(PAGE_DATA[locale].backCall)
  // }

  return (
      <div
          id={'contentWrapper'}
          ref={contentWrapper}
          className={`content-wrapper `}
      >
        <Head>
          <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
          />

          <title>
            Rock-it
          </title>

          <meta
              name="description"
              content="Участвуем в диджитал-трансформации компаний. Делаем нестандартные цифровые решения для бизнеса. Берем шефство над готовым проектом по доработке и администрированию. Вытаскиваем ваш проблемный проект до релиза. Фронтенд, бэкенд, дизайн, ETL, девопс и администрирование"
          />

          <meta
              name={'keywords'}
              content={'RockitStudio, Рокит Студио, IT, Разработка IT-решений, IT-сервисы, IT-консультации, Профессиональная IT-команда, Разработка программного обеспечения, Разработка веб-приложений'}
          />

          <link rel="apple-touch-icon" sizes="180x180" href="./favicons/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="./favicons/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="./favicons/favicon-16x16.png" />
          <link rel="icon" href="./favicons/favicon.ico" />
          <link rel="icon" href="./favicons/favicon.svg" type="image/svg+xml" />

          <link rel="manifest" href="./favicons/site.webmanifest" />
          <link rel="mask-icon" href="./favicons/safari-pinned-tab.svg" color="#ffffff" />
          <meta name="theme-color" content="#ffffff" />
        </Head>

        <Transition
            in={!isHidePreloader}
            timeout={500}
            mountOnEnter
            unmountOnExit
        >
          {state => (
              <Preloader
                  animState={state}
              />)}
        </Transition>

        <Header scrollWidth={scrollWidth}/>
        <ScrollScreens contentWrapper={contentWrapper}/>
        <StackScreen />
        <TeamScreen />
        <AdvantagesScreen />
        <ReleasesScreen />
        <ContactsScreen />
      </div>
  )
}

