import React, {useMemo} from 'react'
import fire from '../../../public/images/main/fire.svg'
import Image from "next/image";
import Constants from "./const";
import {localeSelectors} from "../../../models/locale";
import useWindowSize from "../../../hooks/windowSizeHook";

const Fire = (
  {
    substrRef,
    elementRef,
  }) => {
  const locale = localeSelectors.useValue()
  const size = useWindowSize()

  const params = useMemo(() => {
    const substrRect = substrRef.current?.getBoundingClientRect()
      ?? {
        top: 0,
        left: 0
      }
    const substrWidth = substrRef.current?.offsetWidth ?? 0
    const substrHeight = substrRef.current?.offsetHeight ?? 0
    const windowYOffset = typeof window === 'undefined'
      ? 0
      : window.scrollY
    const fireOffsetTop = substrRect.top + windowYOffset - substrHeight * Constants.fireHeightFactor
    const fireOffsetLeft = substrRect.left - substrWidth * Constants.fireWidthFactor
    const fireWidth = Constants.fireLayoutWidth/Constants.substrLayoutWidth * substrWidth

    return {
      fireWidth,
      fireOffsetTop,
      fireOffsetLeft
    }
  }, [size, substrRef, locale])

  return (
    <div
      ref={elementRef}
      className={'fire'}
      style={{
        width: `${params.fireWidth}px`,
        top: `${params.fireOffsetTop}px`,
        left: `${params.fireOffsetLeft}px`,
      }}
    >
      <Image
        src={fire}
        alt={'fire'}
      />
    </div>
  )
}

export default Fire
