import React from "react";
import Image from "next/image";
import BaseCaption from "../../../components/BaseCaption";
import {FormattedMessage} from "react-intl";
import Link from "next/link";

const CaseCard = ({caseInfo, id}) => {
  return (
    <div /*href={`/cases/${id}`}*/ className={'case-card'}>
      <div className="case-card__img">
        <Image
          src={caseInfo.img}
          alt={'case-image'}
        />
      </div>

      <BaseCaption isSmall>
        <FormattedMessage id={caseInfo.textId}/>
      </BaseCaption>
    </div>
  )
}

export default CaseCard
