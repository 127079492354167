import React from 'react'
import Image from "next/image";
import BaseHeadline from "../../../components/BaseHeadline";
import {FormattedMessage} from "react-intl";
import BaseCaption from "../../../components/BaseCaption";
import FormattedMsgWithLineBreak from "../../../components/FormattedMsgWithLineBreak";
import {ADVANTAGES_LIST} from "../const";

const Advantage = ({advantageParams}) => {
  return (
    <div className={`advantage ${advantageParams.id}`}>
      <div className="advantage__img">
        <Image
          src={advantageParams.img}
          alt={advantageParams.id}
        />
      </div>

      <div className="advantage__text">
        <BaseHeadline>
          <FormattedMsgWithLineBreak id={advantageParams.titleId}/>
        </BaseHeadline>

        <div>
          <BaseCaption isSmall>
            <FormattedMsgWithLineBreak id={advantageParams.descrId}/>
          </BaseCaption>

          {advantageParams.id === ADVANTAGES_LIST[0].id &&
            <BaseCaption isSmall>
              <FormattedMsgWithLineBreak id={'intellect_description_list'}/>
            </BaseCaption>
          }
        </div>
      </div>
    </div>
  )
}

export default Advantage
