export const getScrollbarWidth = () => {
  if (typeof window === 'undefined') return 0

  const div = document.createElement('div')

  div.setAttribute('class', 'custom-scroll-bar')

  div.style.overflowY = 'scroll'
  div.style.width = '50px'
  div.style.height = '50px'

  document.body.append(div)
  const scrollWidth = div.offsetWidth - div.clientWidth

  div.remove()

  return scrollWidth
}

export const setVHUnit = () => {
  if (typeof window === 'undefined') return 0

  const vh = window.innerHeight * 0.01

  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
