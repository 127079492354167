import React, {useState} from 'react'
import Image from "next/image";
import BaseCaption from "../../../components/BaseCaption";
import FormattedMsgWithLineBreak from "../../../components/FormattedMsgWithLineBreak";
import icon from '../../../public/images/releases/open_release.svg';
import { motion } from "framer-motion"

const ReleaseCard = ({releaseInfo, onClick}) => {
  const activeClass = releaseInfo.available
    ? ''
    : 'disabled'
  const [isHovered, setIsHovered] = useState(false)
  const MotionCaption = motion(BaseCaption)

  return (
    <motion.div
      className={`release-card ${activeClass}`}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      animate={{
        cursor: releaseInfo.available
          ? 'pointer'
          : 'unset'
      }}
      onClick={onClick}
    >
      <div
        className="release-card__preview"
      >
        <motion.div
          className="release-card__img"
          animate={{
            scale: isHovered && releaseInfo.available ? 1.15 : 1,
            // filter: releaseInfo.available
            //   ? 'brightness(1)'
            //   : isHovered
            //     ? 'brightness(1)'
            //     : 'brightness(0.4)'
          }}
        >
          <Image
            src={releaseInfo.img}
            alt={releaseInfo.id}
          />
        </motion.div>

        <motion.div
          className={'release-card__blackout'}
          animate={{
            opacity: releaseInfo.available
              ? isHovered
                ? 1
                : 0
              : 0
          }}
          style={{
            pointerEvents: releaseInfo.available
              ? 'unset'
              : 'none'
          }}
        />

        <motion.div
          className="release-card__icon"
          animate={{
            opacity: releaseInfo.available && isHovered
              ? 1
              : 0
          }}
          style={{
            pointerEvents: releaseInfo.available
              ? 'unset'
              : 'none'
          }}
        >
          <Image
            src={icon}
            alt={'icon'}
          />
        </motion.div>

        {releaseInfo.platformLogo &&
          <div className="release-card__platform">
            <Image
              src={releaseInfo.platformLogo}
              alt={'platform'}
            />
          </div>
        }
      </div>

      <div
        className="release-card__description"

      >
        <MotionCaption
          isSmall
          animate={{
            color: releaseInfo.available && isHovered
              ? '#E9E9E9'
              : '#A4A4A4'
          }}
        >
          <FormattedMsgWithLineBreak id={releaseInfo.descriptionId}/>
        </MotionCaption>
      </div>
    </motion.div>
  )
}

export default ReleaseCard
