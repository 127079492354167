import {FormattedMessage} from "react-intl";
import React from "react";

const FormattedMsgWithLineBreak = ({id, values = {}}) => {
  return <FormattedMessage
    id={id}
    values={{
      ...values,
      br: () => (<br/>)
    }}
  />
}

export default FormattedMsgWithLineBreak
