import React, {useMemo} from 'react'
import stone from '../../../public/images/main/stone.svg'
import Image from "next/image";
import Constants from "./const";
import {localeSelectors} from "../../../models/locale";
import useWindowSize from "../../../hooks/windowSizeHook";

const Stone = (
  {
    substrRef,
    elementRef
  }) => {
  const locale = localeSelectors.useValue()
  const size = useWindowSize()

  const params = useMemo(() => {
    const substrRect = substrRef?.current?.getBoundingClientRect()
      ?? {
        top: 0,
        left: 0
      }
    const substrWidth = substrRef?.current?.offsetWidth ?? 0
    const substrHeight = substrRef?.current?.offsetHeight ?? 0
    const windowYOffset = typeof window === 'undefined'
      ? 0
      : window.scrollY
    const stoneOffsetTop = substrRect.top + windowYOffset - substrHeight * Constants.stoneHeightFactor
    const stoneOffsetLeft = substrRect.left - substrWidth * Constants.stoneWidthFactor
    const stoneWidth = Constants.stoneLayoutWidth/Constants.substrLayoutWidth * substrWidth

    return {
      stoneWidth,
      stoneOffsetTop,
      stoneOffsetLeft
    }
  }, [size, substrRef, locale])

  return (
    <div
      ref={elementRef}
      className={'stone'}
      style={{
        width: `${params.stoneWidth}px`,
        top: `${params.stoneOffsetTop}px`,
        left: `${params.stoneOffsetLeft}px`,
      }}
    >
      <Image
        src={stone}
        alt={'stone'}
      />
    </div>
  )
}

export default Stone
