import React from 'react'
import Image from 'next/image'
import {LETTERS} from "./const";
import {appSelectors} from "../models/appModels";
import {FormattedMessage} from "react-intl";

const Preloader = ({animState}) => {
  const isLettersMoving = appSelectors.isLettersMoving.useValue()

  return (
    <div className={`preloader ${animState}`}>
      <span className="text hide">
        <FormattedMessage id={'preloader_text'}/>
      </span>

      <div className={`preloader__wrapper ${isLettersMoving ? 'moving' : ''}`}>
        {LETTERS.map((letters, i) => {
          return (
            <div
              className={'preloader__letters'}
              key={i}
            >
              {letters.map(letter => {
                return (
                  <div
                    key={letter.id}
                    className={'preloader__letter'}
                  >
                    <div>
                      <Image
                        src={letter.img}
                        alt={letter.id}
                        priority
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          )
        }) }
      </div>

      <span className="text">
        <FormattedMessage id={'preloader_text'}/>
      </span>
    </div>
  )
}

export default Preloader
