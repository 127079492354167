import React from 'react'
import Container from "../../components/Container";
import {ADVANTAGES_LIST} from "./const";
import Advantage from "./components/Advantage";
import {localeSelectors} from "../../models/locale";

const AdvantagesScreen = () => {
  const locale = localeSelectors.useValue()

  return (
    <Container customClass={`advantages ${locale === 'ru' ? 'advantages_ru' : ''}`}>
      <div className="advantages__wrapper">
        {ADVANTAGES_LIST.map(item => <Advantage key={item.id} advantageParams={item}/>)}
      </div>
    </Container>
  )
}

export default AdvantagesScreen
